import React, { useState, useCallback } from "react"
import { useForm } from "react-hook-form"
import { format } from "date-fns"
import queryString from "query-string"
import Spinner from "./components/spinner"
import BirthdayPicker from "./components/birthday-picker"
import { FormType } from "../../types"

type FormProps = {
  form: FormType
  [key: string]: any
}

const UhsmMemberIntake = ({ form, ...props }: FormProps) => {
  const search = props?.location?.search
  const parsedSearch = queryString.parse(search)
  const [stateData, setData] = useState({
    isSubmitting: false,
  })
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()
  
  let dropZoneLabel = undefined

  const nameClasses = `mb-6 p-3 block w-full bg-inputBackground ${errors.name ? `border-red-500 border-2` : ``}`
  const dateOfBirthClasses = `mb-6 p-3 block w-full bg-inputBackground ${errors.dateOfBirth ? `border-red-500 border-2` : ``}`
  const memberIdClasses = `mb-6 p-3 block w-full bg-inputBackground ${errors.memberId ? `border-red-500 border-2` : ``}`
  const emailClasses = `mb-6 p-3 block w-full bg-inputBackground ${errors.email ? `border-red-500 border-2` : ``}`
  const buttonClasses = `p-3 w-full button ${stateData.isSubmitting ? `disabled` : `primary`}`

  const onSubmit = async () => {
    setData({ ...stateData, isSubmitting: true })

    if (typeof marketingDataLayer !== `undefined`) {
      console.log(`Push to GTM data layer`)
      marketingDataLayer.push({
        event: `gaForm`,
        eventCategory: `Form`,
        eventAction: `uhsm-member-intake`,
        eventLabel: location.pathname,
      })
    } else {
      console.log(`GTM data layer is not loaded`)
    }

    try {
      const uhsmMemberForm: HTMLFormElement = document.querySelector("#uhsm-member-intake-v1") || new HTMLFormElement()
      const formData = new FormData()

      for (const key of Object.keys(uhsmMemberForm.elements)) {
        const inputName = uhsmMemberForm[key].name
        formData.append(inputName, uhsmMemberForm[key].value)
      }

      fetch(`/`, { 
        method: "POST",
        // headers: { "Content-Type": "multipart/form-data" },
        // headers: { "Content-Type": "application/x-www-form-urlencoded"},
        body: formData,
      })
        .then(function (result) {
          setData({ ...stateData, isSubmitting: false })

          console.log(result)
          console.log(result.statusText)
          console.log(result.statusText)

          if (result.status >= 200 && result.status < 300) {
            location.assign(`${form.destination}`)
          } else {
            alert(`Your request was not sent successfully.  ${result.statusText}`)
          }
        })
        .catch(function (err) {
          setData({ ...stateData, isSubmitting: false })
          alert(`Your request was not sent successfully.  ${err.message}`)
        })
    } catch (err) {
      setData({ ...stateData, isSubmitting: false })
      alert(`Your request was not sent successfully.  ${err.message}`)
    }
  }

  // Configure form values and validation on form register
  React.useEffect(() => {
    register(`dateOfBirth`, { required: true })
  }, [register])

  return (
    <form id="uhsm-member-intake-v1" name="uhsm-member-intake-v1" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" name="form-name" value="uhsm-member-intake-v1" />

      <div className="max-w-lg mx-auto">
        <input {...register("fullName", { required: true })} type="text" className={nameClasses} placeholder="Name" autoComplete="name" />
        <BirthdayPicker
            name="dateOfBirth"
            initialDate={undefined}
            maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
            className={dateOfBirthClasses}
            placeholderText="Birth Date (mm/dd/yyyy)"
            autoComplete="bday"
            onChange={(date: Date) => {
              const value = date ? format(date, `MM/dd/yyyy`) : ``
              setValue(`dateOfBirth`, value)
            }}
            onTab={(e: Event) => {
              if (e.shiftKey) {
                document.querySelector(`input[name=fullName]`)?.focus();
              }
              else {
                document.querySelector(`input[name=memberId]`)?.focus();
              }
            }}
          />
        <input {...register("memberId", { required: true })} type="text" className={memberIdClasses} placeholder="Member ID #" />
        <input {...register("email", { required: true })} type="email" className={emailClasses} placeholder="Email" autoComplete="email" />
      </div>

      <div className="max-w-lg mx-auto text-center mt-5">
        {Object.keys(errors).length > 0 && (
          <div className="bg-red-400 p-3 mb-2 text-white rounded">{(errors.consent && Object.keys(errors).length === 1 && `Please accept the terms.`) || `Please fix errors in your form.`}</div>
        )}

        <button type="submit" className={buttonClasses} disabled={stateData.isSubmitting}>
          {stateData.isSubmitting && <Spinner className="float-left h-7 w-5 text-white" size={5} color="white" />}
          {form.submitText || `Submit`}
        </button>
      </div>
    </form>
  )
}

export default UhsmMemberIntake